import React from "react";
import { Col, Image } from "react-bootstrap";
import css from "./Header.module.css";

const Header = () => {
  return (
    <Col xs={12} className={css.bgHeader}>
      <h1 className="d-none">Магнітний конструктор Майнкрафт</h1>
      <Image src={"/images/лого.png"} alt="logo" className={css.imglogo} />
      <Col xs={9} md={6} xl={4} className={css.rating}>
        <div className={css.ratingBox}>
          <span className="me-2 pt-1 text-white fw-bold fs-5">4.9/5</span>

          <span
            style={{
              fontSize: "1.5rem",
              display: "inline-block",
              position: "relative",
            }}
          >
            <span
              style={{
                color: "gold",
                position: "absolute",
                width: "92%",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
            >
              ★★★★★
            </span>
            <span style={{ color: "lightgray" }}>★★★★★</span>
          </span>
        </div>

        <div className="text-white fs-5">
          Рейтинг магазину
        </div>
        <div className={css.phone}>
          +38(068)-883-84-93
        </div>
      </Col>
    </Col>
  );
};

export default Header;
